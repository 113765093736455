<template>
  <footer class="site-footer">
    <p>
      Copyright © 2023 
      <a href="https://khunjon.com" target="_new">khun jon</a>
    </p>
    <div>
      <!-- Display current locale -->
      <p>Current Locale: {{ $i18n.locale }}</p>
      
      <!-- Locale selection -->
      <select v-model="$i18n.locale">
        <option value="en">English</option>
        <option value="th">Thai</option>
      </select>
    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter'
}
</script>

<style scoped lang="scss">
.site-footer {
    margin-top: 50px;
    padding: 20px;
    background-color: #f8f9fa;
    text-align: center;
    font-size: 0.8rem;
    color: #6c757d;
}
</style>