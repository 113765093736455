<template>
  <div>
    <Breadcrumb :breadcrumbItems="breadcrumbItems" />
    <div class="category-list">
      <div v-for="item in items" :key="item._id" class="list-item">
        <!-- temporary placeholder image for POC; make dynamic later -->
        <img class="place-image" src="@/assets/primary-ph.png" alt="Placeholder Image" />
        <div class="item-content">
          <h5 class="item-title">
            <router-link :to="{ name: 'PlaceDetail', params: { category: category, id: item._id } }">
              {{ item.name.en }}
            </router-link>
          </h5>
          <h6 class="item-subtitle mb-2 text-muted">
            {{ item[subheadingField] }}
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Breadcrumb from '@/components/BreadcrumbNav.vue';
import client from '@/sanity.js';

export default {
  props: ['listName', 'subheadingField'],
  components: {
    Breadcrumb,
  },
  data() {
    return {
      items: [],
      description: '',
    };
  },
  computed: {
    breadcrumbItems() {
      if (this.listName) {
        return [
          { name: this.listName.replace(/-/g, ' '), route: null }
        ];
      } else {
        return [];
      }
    },
  },
  async mounted() {
    const listName = this.listName || this.$route.params.listName;
    if (listName) {
      this.fetchData(listName);
      
      // Fetch list description
      const listQuery = `*[_type == "list" && slug.current == "${listName}"]`;
      const listData = await client.fetch(listQuery);
      if (listData.length > 0) {
        this.description = listData[0].description;
      }
    }
  },
  methods: {
    async fetchData(listName) {
      try {
        //console.log("Fetching data for list:", listName);

        if (!client) {
          console.error("Sanity client is not defined");
          return;
        }

        const query = `*[_type == "place" && "${listName}" in lists[]->slug.current]`;

        //console.log("Executing query:", query);

        const items = await client.fetch(query);
        this.items = items; // Set items

        //console.log("Fetched items:", items);

        // Debugging line to check if 'items' array is populated
        //console.log("Items array after fetch:", this.items);

      } catch (error) {
        console.error("An error occurred:", error);
      }
    }
  }
};
</script>

<style scoped lang="scss">
@import "@/assets/variables.scss";

.category-list {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px; 
    margin: 20px 0;
    
    @media only screen and (max-width: 768px) {
      margin: 10px 0;
      grid-template-columns: 1fr;
    }
}

.list-item {
    padding: 20px;
    margin: 5px;
    
    @media only screen and (max-width: 768px) {
      margin: 5px 0;
    }
    
    .place-image {
      width: 100%;
      object-fit: cover;
      aspect-ratio: 1 / 1;
      
      @media only screen and (max-width: 768px) {
        height: auto;
      }
    }
}
</style>