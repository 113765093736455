<template>
  <div class="heading-section">
    <router-link to="/">
      <img :src="require('@/assets/us-logo.png')" alt="Upper Sukhumvit">
    </router-link>
    <p class="tagline">
      {{ $t('tagline') }}
    </p>
  </div>
</template>

<style scoped lang="scss">
@import "@/assets/variables.scss";

.heading-section {
    text-align: center;
    margin-bottom: 20px;
    font-size: 2.5rem;
    font-weight: bold;
    margin-top: 0;

    img {
        max-width: 100%;
        height: auto;
        display: block;
        margin: auto;
    }

    @media only screen and (max-width: 768px) {
        margin-top: 10px;
        margin-bottom: 10px;

        img {
            max-width: 80%;
        }
    }

    .tagline {
      font-size: 1rem;
      margin-top: 10px;
      color: $primary-text-color;
    }
    
}
</style>