<template>

  <div>
    <Header />
    <router-view />
    <Footer />
  </div>

</template>

<script>
import Header from './components/AppHeader.vue';
import Footer from './components/AppFooter.vue';

export default {
  components: {
    Header,
    Footer
  }
};
</script>
