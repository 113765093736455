<template>
  <div>
    <!-- Loading State -->
    <div v-if="isLoading" class="loading-container">
      <img src="@/assets/loading.webp" alt="Loading..." class="loading-spinner" />
    </div>
    
    <!-- Error State -->
    <div v-if="error">
      <p>Error loading data. Please try again.</p>
    </div>

    <!-- Lists -->
    <div class="list-container" v-else>
      <div v-for="list in dynamicLists" :key="list._id" class="category-card">
        <div class="card-body">
          <h5 class="card-title">
            <router-link :to="`/list/${list.slug.current}`">
              {{ list.title && list.title.en ? list.title.en : 'N/A' }}
            </router-link>
          </h5>
          <!-- Optional: Description and other info -->
          <p class="card-description" v-if="list && list.description">
            {{ list.description.en }}
          </p>
        </div>
      </div>
    </div>

    <!-- Articles -->
    <div class="article-container" v-if="!isLoading && latestArticles">
      <div v-for="article in latestArticles" :key="article._id" class="article-card">
        <h3>
          <router-link :to="`/article/${article.slug.current}`">
            {{ article.title }}
          </router-link>
        </h3>
        <p>{{ article.category }}</p>
        <p>{{ article.metaDescription }}</p>
      </div>

      <!-- add dividing line for styling -->
      <div class="divider" v-if="index < latestArticles.length - 1"></div>
    </div>

  </div>
</template>


<script>
import client from '@/sanity.js';

export default {
  metaInfo() {
    return {
      title: this.dynamicLists.length ? `UpperSukhumvit - ${this.dynamicLists[0].title}` : 'UpperSukhumvit - Home',
      meta: [
        { name: 'description', content: 'Discover wellness and healthy living in the Sukhumvit area of Bangkok.' },
        { name: 'keywords', content: 'wellness, health, Bangkok, Sukhumvit, UpperSukhumvit' }
      ]
    };
  },
  data() {
    return {
      currentLanguage: 'en',
      dynamicLists: [],
      latestArticles: [],
      isLoading: false,
      error: null
    };
  },
  mounted() {
    this.isLoading = true;
    Promise.all([this.fetchDynamicLists(), this.fetchLatestArticles()])
      .then(() => {
        this.isLoading = false;
      })
      .catch(err => {
        console.error('Error:', err);
        this.isLoading = false;
        this.error = err;
      });
  },
  methods: {
    // Fetch non-empty lists from Sanity
    async fetchDynamicLists() {
      try {
        const lists = await client.fetch('*[_type == "list"]{title, description, slug, "placeCount": count(*[_type == "place" && references(^._id)])}');
        this.dynamicLists = lists.filter(list => list.placeCount > 0);
        //console.log("Fetched lists:", this.dynamicLists); //debug
      } catch (err) {
        console.error('Error fetching lists:', err);
        throw err; // Re-throw to handle it in Promise.all
      }
    },
    // Fetch latest articles
    async fetchLatestArticles() {
      try {
        const articles = await client.fetch('*[_type == "blog"]{"title": title.en, "category": category, "metaDescription": metaDescription.en, slug, _id}[0..4]');
        this.latestArticles = articles;
      } catch (err) {
        console.error('Error fetching latest articles:', err);
        throw err; // Re-throw to handle it in Promise.all
      }
    },
    // Format list names for display
    formatListName(listName) {
      return listName
        .split('-')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
    }
  }
};
</script>


<style scoped lang="scss">
@import "@/assets/variables.scss";

.list-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: $primary-bg-color;

    @media only screen and (max-width: 768px) {
        padding: 10px;
    }

    @media screen and (min-width: 481px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .card-body {
        padding: 2rem;

        .card-title {
          font-size: 1.8rem;
          padding: 10px 20px 10px 20px;
          margin: 0;
        }

        .card-description {
          padding: 0 20px 10px 20px;
          margin: 0;
        }
    }
}

.article-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    background-color: $primary-bg-color;

    @media only screen and (max-width: 768px) {
        padding: 10px;
    }

    @media screen and (min-width: 481px) {
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-around;
    }

    .article-card {
        border-radius: 15px;

        @media only screen and (max-width: 768px) {
            width: 100%;
            flex: none;
            margin: 5px 0;
        }
    }

    .divider {
        height: 1px;
        background: $secondary-text-color;
        width: 100%;
        margin: 20px 0;
    }
}
</style>