import { createStore } from 'vuex';

const store = createStore({
    state: {
        pageType: 'Unknown',
        breadcrumbs: [],
    },
    mutations: {
        setPageType(state, type) {
            state.pageType = type;
        },
        setBreadcrumbs(state, breadcrumbs) {
          state.breadcrumbs = breadcrumbs;
        },
    },
    actions: {
        trackPageView({ commit }, payload) {
            const { name, path, meta } = payload;
            let pageType;
    
            // Use `name` and `path` instead of `to.name` and `to.path`
            switch (name) {
                case 'Home':
                    pageType = 'HomePage';
                    break;
                case 'PlaceDetail':
                    pageType = 'PlaceDetail';
                    break;
                case 'ArticleDetail':
                    pageType = 'ArticleDetail';
                    break;
                case 'NotFound':
                    pageType = 'NotFound';
                    break;
                default:
                    if (path.startsWith('/list/')) {
                        pageType = 'CategoryList';
                    } else {
                        pageType = 'Other';
                    }
                    break;
            }
    
            commit('setPageType', pageType);
    
            if (window.dataLayer) {
                window.dataLayer.push({
                    event: 'page_view',
                    pagePath: path,
                    pageTitle: meta.title || 'Unknown Page',
                    pageType: pageType,
                });
            }
        },
    }
});

export default store;
