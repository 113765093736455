import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import '@/assets/main.scss';
import VueMeta from 'vue-meta';
import { createI18n } from 'vue-i18n';

// Initialize Vue I18n
const messages = {
  en: { tagline: 'Discover Wellness, Discover Sukhumvit' },
  th: { tagline: 'ยินดีต้อนรับ' }
};

const i18n = createI18n({
  locale: 'en',
  messages
});

const errorHandler = (error, vm, info) => {
  console.error('An error occurred:', error, info);
};

const app = createApp(App);

// Set the error handler
app.config.errorHandler = errorHandler;

// Use various plugins
app.use(VueMeta, {
  // optional pluginOptions
  refreshOnceOnNavigation: true
});

app.use(i18n);
app.use(router);
app.use(store);

app.mount('#app');
