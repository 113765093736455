<template>
  <nav aria-label="breadcrumb">
    <ol class="breadcrumb">
      <li v-for="(item, index) in breadcrumbs" :key="index" class="breadcrumb-item" :class="{ active: index === breadcrumbs.length - 1 }" aria-current="page">
        <router-link v-if="item.path" :to="item.path">
          {{ item.name || item.meta.title }}
        </router-link>
        <span v-else>{{ item.name || item.meta.title }}</span>
      </li>
    </ol>
  </nav>
</template>

<script>
import { mapState } from 'vuex';

export default {
  computed: {
    ...mapState(['breadcrumbs'])
  },
};
</script>


<style scoped lang="scss">
.breadcrumb {
    justify-content: center;
    background-color: transparent;
    padding: 10px 0;
    font-size: 0.9rem;
    margin-bottom: 0;
}

.breadcrumb-item + .breadcrumb-item::before {
    content: "/";
    color: #6c757d;
    padding: 0 8px;
}

.breadcrumb-item {
    & a, & span {
        text-transform: capitalize;
    }

    a {
        color: #333;
    }
}

.breadcrumb-item a:hover,
.breadcrumb-item a:focus {
    color: #007bff;
    text-decoration: none;
}
</style>