<template>
  <div>
    <Breadcrumb :breadcrumbItems="breadcrumbItems" />

    <!-- Loading Spinner -->
    <div v-if="isLoading" class="loading-container">
      <img src="@/assets/loading.webp" alt="Loading..." class="loading-spinner" />
    </div>

    <!-- Content -->
    <div class="detail-container" v-else-if="place">
      
      <div class="place-title">
        <h1>{{ getLocalizedField('name') }}</h1>
      </div>
      
      <div class="place-image">
          <div class="bts-label">
            <img src="@/assets/icons/train.png" alt="Train Station" class="train-icon" />
            {{ place.closest_bts }}
          </div>
          <img src="@/assets/primary-ph.png" alt="Placeholder" />
      </div>
      
      <div class="place-info">
        <div class="text-wrapper">
          
          <div v-if="place.category === 'Restaurant'">
            <div class="restaurant-details">
              <p>Type: <strong>{{ place.cuisine }}</strong></p>

              <p>
                <a v-if="place.menu_link" :href="place.menu_link" target="_blank">
                  <img src="@/assets/icons/menu.png" alt="View Menu" class="menu-icon" />
                  View the Menu
                </a>
              </p>
            </div>
          </div>

          <p>{{ getLocalizedField('description') }}</p>

          <div class="address-container">
            <img src="@/assets/icons/map.png" alt="Address" class="map-icon" />
            <span>{{ place.fullAddress.en }}</span>
          </div>

          <div class="contact-icons">
            <a v-if="place.phone" :href="'tel:' + place.phone">
              <img src="@/assets/icons/phone.png" alt="Telephone Number" class="contact-icon" />
            </a>
            <a v-if="place.website_link" :href="place.website_link" target="_blank">
              <img src="@/assets/icons/web.png" alt="Website Link" class="contact-icon" />
            </a>
            <a v-if="place.line_link" :href="place.line_link" target="_blank">
              <img src="@/assets/icons/line.png" alt="Add Line" class="contact-icon" />
            </a>
            <a v-if="place.facebook_link" :href="place.facebook_link" target="_blank">
              <img src="@/assets/icons/fb.png" alt="Facebook Page" class="contact-icon" />
            </a>
            <a v-if="place.ig_link" :href="place.ig_link" target="_blank">
              <img src="@/assets/icons/ig.png" alt="Instagram Account" class="contact-icon" />
            </a>
          </div>

        </div>
      </div>
      
    </div>

    <!-- Fallback Message -->
    <div class="body-text" v-else>
      Something went wrong. Please try again later.
    </div>
  </div>
</template>


  
<script>
import Breadcrumb from '@/components/BreadcrumbNav.vue';
import client from '@/sanity.js';
import { DEFAULT_LANGUAGE } from '@/constants';

export default {
components: {
    Breadcrumb,
},
data() {
    return {
      currentLanguage: DEFAULT_LANGUAGE,
      pageTitle: 'Place Detail',
      place: null,
      isLoading: true,
    };
},
async created() {
  try {
    const id = this.$route.params.id;
    //console.log("ID:", id);
    const query = `*[_id == "${id}"]{...}`;
    const [place] = await client.fetch(query);
    //console.log("Fetched place:", place);
    this.place = place;
    this.isLoading = false;
  } catch (error) {
    console.error('Error fetching data:', error);
    this.isLoading = false;
  }
},
computed: {
  breadcrumbItems() {
    const items = [];

    if (this.place) {
      items.push(
        { name: this.place.category, route: `/${this.place.category.toLowerCase().replace(/\s+/g, '-')}` }
      );
    } else {
      items.push({ name: 'Loading...', route: null });
    }

    return items;
  },
},
methods: {
  getLocalizedField(field) {
    //console.log("this.place:", this.place);
    //console.log("field:", field);
    //console.log("this.currentLanguage:", this.currentLanguage);
    //console.log("this.place[field]:", this.place?.[field]);
    //console.log("this.place[field][this.currentLanguage]:", this.place?.[field]?.[this.currentLanguage]);

    return this.place?.[field]?.[this.currentLanguage] ?? this.place?.[field]?.[DEFAULT_LANGUAGE] ?? "";
  },
},
};
</script>

<style scoped lang="scss">

@import "@/assets/variables.scss";

.detail-container {
  display: grid;
  grid-template-columns: 1fr 1fr;

  @media(max-width: 767px) {
    grid-template-columns: 1fr;
  }

  .place-title {
      grid-column: 1 / -1;  // Spans across all columns
      text-align: center;
  }

  .place-info {
      grid-column: 2; // Occupies the second column on wider screens

      @media(max-width: 767px) {
        grid-column: 1; // Single column for mobile
      }

      .text-wrapper {
        padding: 15px;
      }
  }

  .place-image {
      grid-column: 1; // Makes sure the image stays in the first column
      position: relative;

      @media(max-width: 767px) {
        grid-column: 1; // Single column for mobile
      }

      .bts-label {
        position: absolute;
        top: 10px;
        right: 10px;
        background-color: rgba(255, 255, 255, 0.8);
        color: #333;
        padding: 5px;
        z-index: 1;
        border-radius: 5px;  // Rounded corners
      
        .train-icon {
          width: 20px; // size of icon
          height: auto;
          margin-right: 1px;
        }
      }  
    
      img {
        width: 100%;
        object-fit: cover;
      }
  }

  .address-container {
    display: flex;
    align-items: center;
  
    .map-icon {
      width: 24px; // size of icon
      height: auto;
      margin-right: 5px;  // Space between icon and text
    }
  }
  
  .restaurant-details {
    display: flex;
    align-items: center;
  
    .menu-icon {
      width: 24px; // size of icon
      height: auto;
      margin-right: 5px;  // Space between icon and text
    }
  }

  .contact-icons {
    display: flex;
    align-items: center;
    gap: 10px;  // Space between icons
    margin-top: 32px;
  
    .contact-icon {
      width: 24px; // size of icon
      height: auto;
    }
  }  
}

</style>