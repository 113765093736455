// router/index.js
import { createRouter, createWebHistory } from 'vue-router';
import store from '@/store';
import HomePage from '@/views/HomePage.vue';
import PlaceDetail from '@/views/PlaceDetail.vue';
import CategoryList from '@/views/CategoryList.vue';

const staticRoutes = [
  {
    path: '/',
    name: 'Home',
    component: HomePage,
    meta: { title: 'Home Page' }
  },
  {
    path: '/bangkok/:id',
    name: 'PlaceDetail',
    component: PlaceDetail,
    meta: { title: 'Place Details' }
  },
  {
    path: '/article/:slug',
    name: 'ArticleDetail',
    component: () => import('@/views/ArticleDetail.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: () => import('@/components/NotFoundComponent.vue'), // Lazy-load
    meta: { title: '404 Not Found' }
  }
];

// Separate array to hold list routes
// If list does not resolve, check if the route exists here
// Was having an issue with dynamic routing, so chose to do static everything
const listRoutes = [
  {
    path: '/list/affordable-massage-bangkok',
    name: 'AffordableMassageBangkok',
    component: CategoryList,
    props: { listName: 'affordable-massage-bangkok' },
    meta: { title: 'Affordable Massage Bangkok' }
  },
  {
    path: '/list/bangkok-tea-and-kombucha',
    name: 'BangkokTeaAndKombucha',
    component: CategoryList,
    props: { listName: 'bangkok-tea-and-kombucha' },
    meta: { title: 'Bangkok Tea and Kombucha' }
  },
  {
    path: '/list/runner-friendly-hotels-bangkok',
    name: 'RunnerFriendlyHotelsBangkok',
    component: CategoryList,
    props: { listName: 'runner-friendly-hotels-bangkok' },
    meta: { title: 'Runner Friendly Hotels Bangkok' }
  },
  {
    path: '/list/healthy-cafes-bangkok',
    name: 'HealthyCafesBangkok',
    component: CategoryList,
    props: { listName: 'healthy-cafes-bangkok' },
    meta: { title: 'Healthy Cafes Bangkok' }
  },
  {
    path: '/list/healthy-fast-casual-bangkok',
    name: 'HealthyFastCasualBangkok',
    component: CategoryList,
    props: { listName: 'healthy-fast-casual-bangkok' },
    meta: { title: 'Healthy Fast Casual Bangkok' }
  },
  {
    path: '/list/vegan-friendly-dining-bangkok',
    name: 'VeganFriendlyDiningBangkok',
    component: CategoryList,
    props: { listName: 'vegan-friendly-dining-bangkok' },
    meta: { title: 'Vegan Friendly Dining Bangkok' }
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes: staticRoutes.concat(listRoutes)
});

router.afterEach((to) => {
  store.dispatch('trackPageView', {
    name: to.name,
    path: to.fullPath,
    meta: to.meta,
  });
});

router.beforeEach((to, from, next) => {
  const breadcrumbs = [];
  
  // Add 'Home' to all breadcrumbs
  breadcrumbs.push({
    name: 'Home',
    path: '/',
    meta: { title: 'Home' },
  });

  // Special case for CategoryList
  if (to.name && to.name.endsWith('Bangkok') /* or however you identify CategoryList routes */) {
    breadcrumbs.push({
      name: to.meta.title,
      path: null, // No link needed
      meta: { title: to.meta.title },
    });
  }

  // Special case for PlaceDetail
  if (to.name === 'PlaceDetail') {
    if (from.name) {
      breadcrumbs.push({
        name: from.meta.title,
        path: from.fullPath,
        meta: { title: from.meta.title },
      });
    }
  }
  
  // Update Vuex state with the new breadcrumb array
  store.commit('setBreadcrumbs', breadcrumbs);

  // Dispatch page tracking
  store.dispatch('trackPageView', {
    name: to.name,
    path: to.fullPath,
    meta: to.meta,
  });

  next();
});

export default router;
